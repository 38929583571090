// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Autoplay, EffectFade } from 'swiper/modules';

const componentSlider = () => {
  var swiper = new Swiper(".swiper", {
    modules: [Autoplay, EffectFade],
    slidesPerView: 1,
    loop: true,
    threshold: 10,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    speed: 2000,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
  });
}


export default componentSlider;