import * as echarts from 'echarts';

const componentChart = () => {

    const observeOptions = {
        root: null,
        threshold: 0.75,
    };

    const chartTextStyle = {
        color: '#878787',
        fontWeight: 'normal',
        fontSize: 13,
        fontFamily: '"GenosGFG", Arial, sans-serif',
    }

    const chartLegendStyle = {
        top: 'bottom',
    }

    const chartGridStyle = {
        left: '3%',
        right: '4%',
        bottom: '8%',
        containLabel: true
    }

    const bilanzsumme = document.getElementById('bilanzsumme')
    let echartBilanzsumme;
    if (bilanzsumme) {
        echartBilanzsumme = echarts.init(bilanzsumme);

        const observeEchartBilanzsumme = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartBilanzsumme.setOption({
                        title: {
                            text: 'Entwicklung der Bilanzsumme in Mio. Euro',
                            textStyle: {
                                color: '#878787',
                                fontWeight: 'normal',
                                fontSize: 13,
                                fontFamily: '"GenosGFG", Arial, sans-serif',
                            },
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        color: [
                            '#96BEE6'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        legend: {},
                        grid: chartGridStyle,
                        xAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: ['2019', '2020', '2021', '2022', '2023'],
                            splitLine: {
                                show: true
                            },

                        },
                        series: [
                            {
                                type: 'bar',
                                data: [1235, 1433, 1604, 1686, {
                                    value: 1665,
                                    itemStyle: {
                                        color: '#0066b3'
                                    }
                                },]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartBilanzsumme.observe(bilanzsumme);
    }


    const bilanzsummeDonut = document.getElementById('bilanzsummeDonut')
    if (bilanzsummeDonut) {
        var echartBilanzsummeDonut = echarts.init(bilanzsummeDonut);
        echartBilanzsummeDonut.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Bilanzsumme',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 2162205616, name: 'Bilanzsumme' },
                    ]
                }
            ]
        });
    }

    const bilanzsummeDonutDetail = document.getElementById('bilanzsummeDonutDetail')
    if (bilanzsummeDonutDetail) {
        var echartBilanzsummeDonutDetail = echarts.init(bilanzsummeDonutDetail);
        echartBilanzsummeDonutDetail.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Bilanzsumme',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 2162205616, name: 'Bilanzsumme' },
                    ]
                }
            ]
        });
    }

    const kundenkredite = document.getElementById('kundenkredite')
    let echartkundenkredite;
    if (kundenkredite) {
        echartkundenkredite = echarts.init(kundenkredite);

        const observeEchartkundenkredite = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartkundenkredite.setOption({
                        title: {
                            text: 'Kundenkreditvolumen in Mio. Euro (gerundet)',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        color: [
                            '#96BEE6','#0066b3', '#cccccc', '#ff6600'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        legend: {},
                        grid: chartGridStyle,
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: ['2021', '2022', '2023'],
                            splitLine: {
                                show: true
                            },
                        },
                        series: [
                            {
                                name: 'Gesamt',
                                type: 'bar',
                                data: [1549,1676,1725]
                            },
                            {
                                name: 'Bank',
                                type: 'bar',
                                data: [1245,1361,1423]
                            },
                            {
                                name: 'Avale',
                                type: 'bar',
                                data: [98,92,87]
                            },
                            {
                                name: 'Verbundpartner',
                                type: 'bar',
                                data: [206,223,215]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartkundenkredite.observe(kundenkredite);
    }



    const kundenanlagevolumen = document.getElementById('kundenanlagevolumen')
    let echartkundenanlagevolumen;
    if (kundenanlagevolumen) {
        echartkundenanlagevolumen = echarts.init(kundenanlagevolumen);

        const observeEchartkundenanlagevolumen = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartkundenanlagevolumen.setOption({
                        title: {
                            text: 'Kundenanlagevolumen in Mio. Euro (gerundet)',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        color: [
                            '#96BEE6','#0066b3', '#ff6600'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        legend: {},
                        grid: chartGridStyle,
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: ['2021', '2022', '2023'],
                            splitLine: {
                                show: true
                            },
                        },
                        series: [
                            {
                                name: 'Gesamt',
                                type: 'bar',
                                data: [2444,2436,2521]
                            },
                            {
                                name: 'Bank',
                                type: 'bar',
                                data: [1567,1608,1613]
                            }, 
                            {
                                name: 'Verbundpartner',
                                type: 'bar',
                                data: [877,828,908]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartkundenanlagevolumen.observe(kundenanlagevolumen);
    }



    const kundeneinlagen = document.getElementById('kundeneinlagen')
    let echartkundeneinlagen;
    if (kundeneinlagen) {
        echartkundeneinlagen = echarts.init(kundeneinlagen);

        const observeEchartkundeneinlagen = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartkundeneinlagen.setOption({
                        title: {
                            text: 'Entwicklung der Kundeneinlagen und der Wertpapierbestände in Mio. Euro*',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        color: [
                            '#96BEE6', '#0066b3'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        legend: {},
                        grid: chartGridStyle,
                        xAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: ['2019', '2020', '2021', '2022', '2023'],
                            splitLine: {
                                show: true
                            },
                        },
                        series: [
                            {
                                name: 'Wertpapiere',
                                type: 'bar',
                                data: [222, 255, 397, 395, 496]
                            },
                            {
                                name: 'Einlagen',
                                type: 'bar',
                                data: [895, 1048, 1144, 1257, 1242]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartkundeneinlagen.observe(kundeneinlagen);
    }

    const eigenkapital = document.getElementById('eigenkapital')
    let echarteigenkapital;
    if (eigenkapital) {
        echarteigenkapital = echarts.init(eigenkapital);

        const observeEcharteigenkapital = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echarteigenkapital.setOption({
                        title: {
                            text: 'Bilanzielles Eigenkapital in Mio. Euro (gerundet)',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            // axisPointer: {
                            //     type: 'shadow'
                            // }
                        },
                        color: [
                            '#96BEE6', '#0066b3'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        grid: chartGridStyle,
                        yAxis: {
                            type: 'value',
                            min: 220, // Startet die Y-Achse bei 200
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: ['2021', '2022', '2023'],
                            splitLine: {
                                show: false
                            },
                        },
                        series: [
                            {
                                name: 'Eigenkapital',
                                type: 'bar',
                                barWidth: '50%', // Reduziert die Breite der Balken
                                data: [233, 235, {
                                    value: 239,
                                    itemStyle: {
                                        color: '#0066b3'
                                    }
                                }]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEcharteigenkapital.observe(eigenkapital);
    }

    const mitgliederentwicklung = document.getElementById('mitgliederentwicklung')
    let echartmitgliederentwicklung;
    if (mitgliederentwicklung) {
        echartmitgliederentwicklung = echarts.init(mitgliederentwicklung);

        const observeEchartmitgliederentwicklung = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartmitgliederentwicklung.setOption({
                        title: {
                            text: 'Mitgliederentwicklung (Anzahl der Mitglieder)',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        color: [
                            '#96BEE6', '#0066b3'
                        ],
                        valueFormatter: (value) => value.toLocaleString(),
                        grid: chartGridStyle,
                        xAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: (value) => value.toLocaleString()
                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: ['2019', '2020', '2021', '2022', '2023'],
                            splitLine: {
                                show: true
                            },
                        },
                        series: [
                            {
                                name: 'Mitgliederentwicklung',
                                type: 'bar',
                                data: [19912, 20102, 21633, 21586, {
                                    value: 21520,
                                    itemStyle: {
                                        color: '#0066b3'
                                    }
                                }]
                            },
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartmitgliederentwicklung.observe(mitgliederentwicklung);
    }

    const kundenkredite2 = document.getElementById('kundenkredite2')
    let echartkundenkredite2;
    if (kundenkredite2) {
        echartkundenkredite2 = echarts.init(kundenkredite2);

        const observeEchartkundenkredite2 = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartkundenkredite2.setOption({
                        title: {
                            text: 'Gliederung der Kredite nach Branchen in Prozent',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        color: [
                            '#0066b3', '#327DC8', '#649BD7', '#96BEE6', '#ff6600'
                        ],
                        tooltip: {
                            // formatter: '{b}: {c}%'
                            valueFormatter: (value) => value.toLocaleString() + '%',
                        },
                        legend: {},
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '80%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { value: 32, name: 'Privatkredite' },
                                    { value: 31, name: 'Dienstleistungen' },
                                    { value: 17, name: 'Landwirtschaft' },
                                    { value: 14, name: 'Industrie und Gewerbe' },
                                    { value: 6, name: 'Handel' },
                                ]
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartkundenkredite2.observe(kundenkredite2);
    }

    const aktivseite = document.getElementById('aktivseite')
    let echartAktivseite;
    if (aktivseite) {
        echartAktivseite = echarts.init(aktivseite);

        const observeEchartAktivseite = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartAktivseite.setOption({
                        title: {
                            text: 'Die Aktivseite der Bilanz in Mio. Euro',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        color: [
                            '#0066b3', '#327DC8', '#649BD7', '#96BEE6', '#ff6600'
                        ],
                        valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        tooltip: {
                            // formatter: '{b}: {c}'
                        },
                        legend: {
                            padding: [
                                0,  // up
                                0, // right
                                10,  // down
                                0, // left
                            ]
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '75%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { value: 1261, name: 'Kundenkredite' },
                                    { value: 144, name: 'Barreserve und Bankforderungen' },
                                    { value: 205, name: 'Wertpapierbestand' },
                                    { value: 46, name: 'Beteiligungen und Sachanlagen' },
                                    { value: 9, name: 'Sonstige' },
                                ]
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartAktivseite.observe(aktivseite);
    }

    const passivseite = document.getElementById('passivseite')
    let echartPassivseite;
    if (passivseite) {
        echartPassivseite = echarts.init(passivseite);

        const observeEchartPassivseite = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    echartPassivseite.setOption({
                        title: {
                            text: 'Die Passivseite der Bilanz in Mio. Euro',
                            textStyle: chartTextStyle,
                            right: 0,
                            bottom: 0
                        },
                        color: [
                            '#0066b3', '#327DC8', '#649BD7', '#ff6600'
                        ],
                        tooltip: {
                            // formatter: '{b}: {c}'
                            valueFormatter: (value) => value.toLocaleString() + ' Mio. €',
                        },
                        legend: {},
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '80%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { value: 1242, name: 'Kundeneinlagen' },
                                    { value: 218, name: 'Refinanzierungsmittel' },
                                    { value: 190, name: 'Eigenkapital' },
                                    { value: 15, name: 'Sonstige' },
                                ]
                            }
                        ]
                    });
                }

                return
            });
        }, observeOptions);

        observeEchartPassivseite.observe(passivseite);
    }

    window.onresize = function () {
        if (bilanzsumme) {
            echartBilanzsumme.resize();
        }
        if (bilanzsummeDonut) {
            echartBilanzsummeDonut.resize();
        }
        if (bilanzsummeDonutDetail) {
            echartBilanzsummeDonut.resize();
        }
        if (kundenkredite) {
            echartkundenkredite.resize();
        }
        if (kundenanlagevolumen) {
            echartkundenanlagevolumen.resize();
        }
        if (kundenkredite2) {
            echartkundenkredite2.resize();
        }
        if (kundeneinlagen) {
            echartkundeneinlagen.resize();
        }
        if (eigenkapital) {
            echarteigenkapital.resize();
        }
        if (mitgliederentwicklung) {
            echartmitgliederentwicklung.resize();
        }
        if (aktivseite) {
            echartAktivseite.resize();
        }
        if (passivseite) {
            echartPassivseite.resize();
        }
    };
};



export default componentChart;

